import { updateTreatmentsFromSplit } from './internal-state.js'
import { LOGGER_NAME, getLogger } from './logger.js'
import { initializeSplitClient } from './split-client/initialize-split-client.js'

const splitKeys = [
  'comment-stream-consume-project-events',
  'custom-form-calculation-engine',
  'parameter-name-non-editable',
  'localization-list',
  'react-router-v6-compat',
  'proof-observer-as-user',
  'quip-document-integration',
  'unified-share-backend',
  'unshim-delete-task-dialog',
  'maestro-workspace',
  'calculations-before-after-state-wildcard',
  'custom-data-multiselect-value-as-list',
  'wf-planning-formula-field-return-type',
  'wf-environment-promotion',
  'use-aws-storage-provider',
  'ai-agent-maestro-expert',
  'csrf-session-cookie',
  'outlook-workfront-oauth2-authorization',
  'agile-boards-dark-mode',
  'post-message-location',
  'separate-multiselect',
  'maestro-signed-asset-urls',
  'segmented-dated-rates',
  'comments-in-system-activities',
  'iris-kafka-alternate-send',
  'maestro-calendar-view',
  'form-builder-options-builder-refactor',
  'maestro-enablement',
  'request-primary-contact-user-suggestion-option-new-ui',
  'maestro-table-sorting',
  'email-daily-digest-action-needed',
  'sp-redrock-sp-client-host',
  'set-data-extension-values-synchronized',
  'asset-approvals-suggest-participants',
  'company-permissions',
  'diagnostics-okta',
  'wf-planning-search-improvement',
  'custom-data-validate-date',
  'update-stream-comment-size-limit',
  'asset-approvals-stage-deadline',
  'frame-comments-sync',
  'canvas-dashboards-web-content',
  'documents-perform-customer-docsize-update',
  'agile-boards-import-subtasks',
  'users-ui',
  'toggles-mfe-promise-deprecation-warning',
  'data-extension-v1',
  'redrock-api-expert-no-put-or-delete',
  'rollup-custom-field',
  'proofing-genstudio-toggle',
  'maestro-field-change-history',
  'transactional-outbox-uow-error-retry-bizcontext-commit',
  'set-data-extension-values-logs',
  'wf-integrations-download-s3-native',
  'billable-non-billable-expense-calculation',
  'wf-integration-frameio-configurations',
  'update-undo',
  'aem-updated-experience-manager',
  'custom-forms-ignore-defaults',
  'comment-stream-search-for-comment-content',
  'transactional-outbox-bizcontext-debug',
  'maestro-group-permissions',
  'custom-reminders-sent-hourly',
  'eauth-login-as-non-ims',
  'assigment-user-suggestion-option-new-ui',
  'uxp-plugin-notifications',
  'assign-multiple-teams',
  'react-router-v6',
  'voyager-custom-form-group-data-extendable',
  'transactional-outbox-validate-object-space',
  'comments-boards-access-check',
  'genstudio-filter-sorting',
  'sylon-enabled',
  'maestro-reusable-record-type',
  'proof-deprecate-mobile-app',
  'work-per-day-migration-job',
  'billing-rate-cards',
  'glide-billing-rates-lists',
  'unified-approvals-multi-stage',
  'usestorageproviderexternalthumbnails',
  'use-postgres-time-grouping-functions',
  'project-bulk-edit-logs',
  'timesheet-hour-preferences-ms',
  'transactional-outbox-uow-error-retry',
  'iteration-custom-data-reactified',
  'bookings',
  'ai-agent-wf-arrow-expert',
  'hierarchy-rates',
  'unshim-request-access-dialog',
  'large-document-upload-aws',
  'event-subscription-customers-on-event-hub',
  'homepage-delegate-mvp',
  'daystrom-exc-redirect',
  'wf-planning-view-permissions-per-license',
  'agile-boards-remove-updateconnectedcards',
  'maestro-new-templates',
  'wf-planning-object-limits',
  'attach-template-predecessor',
  'company',
  'maestro-images-gallery',
  'financial-custom-fields',
  'licenses-mfe',
  'maestro-timeline-record-breakdown',
  'transactional-outbox-send-null-instead-of-infinity',
  'wf-planning-landing-page-workspaces-interaction',
  'business-profiles',
  'groupings-ui-mvp',
  'disable-data-dog-rum',
  'canvas-dashboards-table',
  'maestro-search-improvement',
  'group-status-enhancements',
  'add-canvas-dashboards-nav-item',
  'quicksilver-list-controller-no-tile',
  'unshim-resource-pools',
  'disable-google-team-drives',
  'allow-ims-user-deletion',
  'activity-log-transport',
  'frame-project-integration',
  'update-stream-emoji-picker',
  'maestro-auth-z-connection-consistency-minimal-latency',
  'hour-category-custom-data',
  'maestro-auth-z-bulk',
  'external-calendar-write-back',
  'workfront-dam-force-enabled',
  'proof-soap-use-list-providers',
  'limit-registration-access',
  'project-snapshot',
  'maestro-activity-log',
  'list-RTBE-subscription-optimizations',
  'email-new-work-request',
  'doc-folder-sharing-lvl-restriction',
  'new-convert-issue-to-task-issue-list',
  'wf-frameio-project-status-trigger-push-documents',
  'polar-todos-list',
  'large-document-upload-azure',
  'date-effective-exchange-rates',
  'wf-planning-ims-support-in-wf',
  'polar-in-nwe',
  'document-version-approvals',
  'copy-budgeted-hours',
  'ai-agent-maestro-expert',
  'unified-share-modal',
  'global-hours-edit-fix',
  'unshim-list-template-billing-rates',
  'calculation-engine-objcodes',
  'recurring-task-create-planned-hours',
  'unshim-list-team-task',
  'date-effective-home-group',
  'maestro-new-user-onboarding',
  'timeline-tooltips',
  'track-shim-loads',
  'time-phased-service',
  'new-convert-issue-to-task',
  'redrock-comments-consumer-retry',
  'bulletproof-proof-awaiting-approval-filter',
  'email-predecessor-task-complete-update',
  'workload-balancer-highlight-contoured-items',
  'ai-auto-generate-document-description',
  'agile-boards-list-view',
  'comment-stream-consume-optask-events',
  'react-18',
  'user-async-delete',
  'unshim-legacy-reporting-matrix-view',
  'activity-log-multi-tenant-migration-write',
  'external-lookup-label',
  'asset-approvals-soft-delete-restore',
  'ims-users-sync-precommit',
  'disable-file-upload-non-redrock-objects',
  'uxp-plugin-proof-settings-overlay',
  'date-effective-user-job-roles',
  'canvas-dashboard-enabled-entities',
  'task-story-details',
  'proxy-datadog-rum',
  'custom-applications',
  'asset-approvals-lock-unlock',
  'maestro-landing-page',
  'is-upp-version-2',
  'proofing-genstudio-events-outbox',
  'setup-system-preferences-ui',
  'canvas-dashboards-all-approvals',
  'unshim-users-edit-page',
  'sortable-and-groupable-lookup-fields',
  'exchange-rate-date',
  'maestro-generative-thumbnail',
  'sso-ui-tempalte-mapping',
  'support-labor-revenue',
  'non-labor-resources',
  'canvas-dashboards-skip-auth-check',
  'ai-assistants',
  'polar-onboarding',
  'ai-agent-canvas-dashboards-expert',
  'agile-story-points',
  'api-pre-load-json-response',
  'intake-action-buttons',
  'use-execute-bulk-update-for-tasks',
  'rm-reporting-new-ui',
  'maestro-details-group-drag-and-drop',
  'project-rich-text-description',
  'maestro-import-record-types',
  'event-subscription-send-all-updates',
  'ai-reviewers',
  'voyager-email-notifications-group-specific-apply',
  'maestro-view-global-sharing',
  'unshim-template-risks',
  'project-header-user-suggestion-option-new-ui',
  'parameter-value-date-time-utc-save',
  'is-billing-system-needed',
  'timesheet-details-unshimmed-endpoint',
  'form-builder-preview-logic',
  'proof-viewer-opens-tab',
  'unshim-document-sharepoint-access',
  'sharepoint-search-sites',
  'documents-custom-data-reactified',
  'wf-frameio-frame-enabled-users-on-tasks',
  'maestro-multilevel-lookup',
  'new-home-new-commenting-service',
  'subgroup-admins',
  'wf-frameio-group-icons',
  'preferences-master',
  'maestro-view-service-redux',
  'asset-approvals-templates',
  'update-stream-tag-performance',
  'ai-agent-boards-expert',
  'wpd-issue-resolving-obj',
  'unshim-legacy-report-builder',
  'separate-cost-bill-permissions',
  'rate-location-classifiers',
  'unshim-view-document-versions',
  'assignment-suggestion-unified-experience',
  'adobus-use-mc2-test-topic',
  'wf-frameio-integration',
  'project-hidden-default-billing-rates',
  'skip-quicksilver-server-redirects-for-dashboard-sites',
  'inline-assignment-user-suggestion-option-new-ui',
  'asset-approvals-dispatch-authz',
  'legacy-web-redirect-filter',
  'form-builder-redrock-data',
  'add-canvas-dashboards-beta-button',
  'parameter-delete-cache-invalidation',
  'proof-cmk',
  'documents-multipart-upload',
  'maestro-feedback-internal',
  'business-rule',
  'wf-frameio-api-uses-ims-auth',
  'assignment-rate-overrides',
  'maestro-reusable-record-type-system-wide',
  'wf-planning-engine-disable-read-kafka-event',
  'billable-non-billable-expense-data-migration',
  'frameio-creative-folder-sync',
  'update-stream-images',
  'tenant-login-marketing-banner',
  'maestro-create-template-from-workspace',
  'legacy-custom-forms-reorder',
  'string-is-blank-fix',
  'document-widget',
  'comments-drag-and-drop-image',
  'wf-planning-write-mongodb-job',
  'comments-box-style',
  'agile-boards-query-board',
  'maestro-header-objects-search',
  'maestro-timeline-calendar-rtbe',
  'maestro-calendar-week-view',
  'wf-frameio-open-button',
  'unshim-legacy-filter-builder',
  'maestro-grid-grouping',
  'rtbe-maestro-authz-integration',
  'workfront-proof-users-sync-rest',
  'project-work-per-day-population',
  'disable-legacy-preferences',
  'use-search-ui-components',
  'commenting-toggle-removal',
  'agile-boards-scrum-next-iteration',
  'react-list-lazy-loading-skip-first-chunk',
  'unshim-setup-email',
  'picasso-timesheet-bulk-edit-save-fix',
  'agile-boards-abtest-projectboard',
  'group-default-projects-status',
  'user-location-classifiers',
  'custom-field-refactoring',
  'wss-fusion-azure-provisioning',
  'transactional-outbox-add-accessor-ids',
  'recalculate-date-discrepancies',
  'workfront-articles',
  'enforce-field-access-for-expression-dependencies',
  'activity-log',
  'hierarchic-locations',
  'unified-share-bulk',
  'autoprovision-wf-sso-user-to-proofing',
  'assignment-category-custom-data',
  'wf-planning-tms',
  'api-wizard-expert',
  'date-effective-rates',
  'activity-log-multi-tenant-migration',
  'search-elastic-cloud',
  'transactional-outbox-dynamic-fields-issue',
  'calendar-view-settings',
  'approval-on-rejected-status',
  'more-calendar-sections',
  'sub-group-statuses',
  'form-builder-layout-toggle',
  'resource-planning-mfe-migration',
  'uxp-plugin-multiple-assignments',
  'track-splits-in-launch',
  'non-labor-resources-in-financial-calculations',
  'maestro-comments-notifications',
  'maestro-details-pages',
  'aem-ims-hack',
  'new-form-builder-sharing',
  'business-rules-localization',
  'timephased-widget',
  'intake-mfe',
  'intake-public-recordtype-connection-enabled',
  'intake-spectrum-fileds',
  'iris-rr-notification-service-redirect',
  'attask-cookie-http-only-for-api',
  'ptos-in-workload-balancer',
  'shape-up-mfe',
  'wf-frameio-project-push-needs-tasks',
  'wf-frameio-block-proof-creation',
  'custom-field-list-add',
  'shell-datadog-logging',
  'comments-copy-paste-image',
  'proof-account-settings-nwe',
  'maestro-auto-filtering',
  'transactional-outbox-update-accessor-ids',
  'maestro-brief-thumbnail-image',
  'transactional-outbox-deny-listed-objects',
  'monaco-formula-ai',
  'workflows-publish-assets',
  'widget-assignments-cja-tracking',
  'document-provider-secure-field',
  'secure-restricted-marshaller',
  'xfiles-proof-job-bean-imporovements',
  'form-builder-ga',
  'unshim-sharing-dialog',
  'async-timeline-calculation',
  'app-builder',
  'event-subscription-filter-irrelevant-events',
  'wf-frameio-ims-url-redirect',
  'canvas-dashboard-kpi',
  'new-commenting-experience',
  'eauth-login-as-audit-retry',
  'ui-templates-packaging-integration',
  'maestro-connection-fields-in-detail-page-for-genstudio',
  'update-stream-suggestions-list-new-ui',
  'authz-authorization-validation',
  'filter-hard-bounce-ses-emails',
  'disable-email-spoofing',
  'unshim-legacy-view-builder',
  'request-draft-multi-tenant',
  'aem-resource-asset-metadata',
  'asynchronous-task-save',
  'attribute-based-rate-calculations',
  'request-drafts-list-mfe',
  'agile-project-burndown-chart',
  'maestro-timeline-calendar-by-lookup-fields',
  'update-stream-data-sync',
  'unshim-export-dialog',
  'editable-condition-commited-date-fields',
  'agile-boards-metrics',
  'comments-joint-tab',
  'project-filter-hour-type-radio',
  'job-roles-location-based-rates',
  'comment-stream-on-behalf-of',
  's3-bucket-uploads-security-concern',
  'project-details-user-suggestion-option-new-ui',
  'transactional-outbox-calculated-parameter-values',
  'comment-stream-consume-document-events',
  'maestro-view-public-sharing',
  'useStorageProviderInExternalThumbnails',
  'asset-approvals-create-approval-internal',
  'maestro-user-field',
  'boardwalk-temporary-logging',
  'maestro-toolbar-view-actions',
  'pacific-sync-object-life',
  'new-move-template-task',
  'drive-unrestricted-scope-only',
  'rm-reporting',
  'custom-expression-validation',
  'approvals-simplified-events-consumer-refactor',
  'doc-details-extension-deprecation',
  'iterations-new-commenting-experience',
  'mobile-saml-scheme-validate',
  'wf-planning-engine-read-mongodb-job',
  'bizcontext-issue-debug',
  'maestro-details-drag-and-drop',
  'dont-reset-list-data',
  'maestro-workspace-layout-template',
  'wf-frameio-project-creation-trigger',
  'hour-merge-disabled',
  'comments-user-details-dialog',
  'comment-stream-consume-task-events',
  'wf-integrations-aem-upload-multithreaded',
  'issue-proof-document-not-sharing',
  'update-stream-performance-replies',
  'custom-data-multi-select-value-export',
  'separate-parameter-values-multiselect-expression',
  'saml-handler-host-whitelist',
  'maestro-record-full-state-publish',
  'rate-card-import',
  'iris-mailgun-send-email-smtp',
  'maestro-add-new-record',
  'canvas-dashboards-multi-series-chart',
  'maestro-progress-bar-visualization',
  'maestro-details-dialog-add-cover-photo',
  'maestro-connected-record-display-options',
  'field-rename-update-in-prompts',
  'ai-chat-mfe',
  'date-effective-other-groups',
  'disable-profile-cache-invalidation',
  'document-frameio-integration',
  'send-to-pulse',
  'request-draft-byok',
  'create-folder-workflow-expanded-field-type-support',
  'transactional-outbox-disable-loading-accessor-ids-separately',
  'maestro-access-request',
  'ai-agent-redrock-api-expert',
  'duplicate-key-error-log',
  'unlocked-statuses-in-approval-processes',
  'sso-try-with-new-cert',
  'request-draft-service-jwt',
  'wf-frameio-user-deactivation-deletion',
  'intelligent-requests-ai',
  'comments-in-minix',
  'ai-agent-wf-risk-expert',
  'diagnostics-ldap',
  'maestro-thumbnail-cover-random-assign',
  'wf-frameio-flag-wf-users',
  'voyager-preferences-task-issue-apply',
  'iris-mailgun-send-email-api',
  'maestro-grid-action-bar',
  'agile-boards-scrum-goals',
  'enable-data-access-in-setup',
  'mobile-mfe',
  'group-async-replace-delete',
  'maestro-new-filter',
  'oauth2-redirect-uri-check',
  'sso-on-user-copy-enable',
  'log-hour-inconsistency-fix',
  'comment-stream-consume-note-events',
  'approval-user-identifiers',
  'iris-in-app-user-notification-logging',
  'frm-custom-release',
  'unshim-send-user-update-dialog',
  'wf-frame-group-integration-ui',
  'react-spectrum-import-map',
  'unshim-document-webhooks-access',
  'intelligent-intake-expert',
  'form-builder-dnd-binary',
  'unsaved-changes-dialog-spectrumified',
  'historical-proof-document-decisions',
  'maestro-gallery-save-url',
  'pacific-child-id-optimized-collector',
  'calculation-date-utc',
  'api-default-version',
  'group-admin-edit-federation-id',
  'react-list-toolbars',
  'business-rule-formula-generation',
  'release-channels',
  'keep-first-approval-information',
  'activity-log-service',
]

/**
 * @typedef InitOptions
 * @property {'split' | 'wf' | 'public'} mode  From where to pull feature flag values
 * @property {SplitInitOptions} [splitInitOptions]  Initialization options for split SDK
 */

/**
 * @typedef SplitInitOptions
 * @property {String} key  Split browser key to use
 * @property {String} tenantID  Customer identifier
 * @property {String} [userID]  User identifier
 * @property {Object} attributes  Custom attributes
 * @property {Object} sdkSettings  Split SDK settings
 */

const defaultOptions = {
  mode: 'wf',
}

export const getPublicToken = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return urlSearchParams.get('publicToken') ?? ''
}

const updateSplitsFromWorkfront = async () => {
  try {
    const { namedRequest, NAMED_REQUESTS } = await System.import('@wf-mfe/api')
    const treatments = await namedRequest(NAMED_REQUESTS.splits)
    updateTreatmentsFromSplit(treatments || {})
  } catch (error) {
    const logger = await getLogger()
    logger.warn(
      `${LOGGER_NAME}: Initializing split via network call has failed.`,
    )
    updateTreatmentsFromSplit({})
  }
}

/**
 * Initialize the Split client to retrieve split values.
 * This is done during the page bootstrap.
 * Microfrontends should never need to use this.
 * @param {InitOptions} options
 * @returns {Promise<void>}
 */
export async function initialize(options = defaultOptions) {
  if (options.mode === 'public') {
    try {
      // TODO: this is temporary until we have a better way to handle public pages split initialization
      const publicToken = getPublicToken()

      let tenantId

      try {
        tenantId = atob(publicToken).split('_')[0]
      } catch (error) {
        // unable to parse tenantId from publicToken - aka RedRock publicToken instead of WF planning
      }

      if (tenantId) {
        const initOptions = {
          key: window.splitKey,
          sdkSettings: {
            sync: {
              enabled: false,
              impressionsMode: 'NONE',
              splitFilters: [],
            },
            core: {
              labelsEnabled: false,
            },
          },
          tenantID: tenantId,
          userID: 'fakeUserId',
          attributes: {
            serviceID: 'quicksilver',
          },
        }

        const client = await initializeSplitClient(window.splitKey, initOptions)
        const treatmentsWithConfig = client?.getTreatments(splitKeys)

        const splitValues = Object.entries(treatmentsWithConfig).reduce(
          (acc, [key, value]) => {
            acc[key] = { treatment: value, config: null }
            return acc
          },
          {},
        )

        updateTreatmentsFromSplit(splitValues || {})
      } else {
        await updateSplitsFromWorkfront()
      }
    } catch (error) {
      const logger = await getLogger()
      logger.error(
        `${LOGGER_NAME}: Error initializing Split client - ${error.message}`,
      )
    }
  } else if (options.mode === 'wf' || typeof options === 'string') {
    await updateSplitsFromWorkfront()
  } else if (options.mode === 'split') {
    const { key, ...initOptions } = options.splitInitOptions

    if (Array.isArray(initOptions.sdkSettings.sync.splitFilters)) {
      try {
        const client = await initializeSplitClient(key, initOptions)
        const flagSets = initOptions.sdkSettings.sync.splitFilters
          .filter((splitFilter) => splitFilter.type === 'bySet')
          .flatMap((splitFilter) => splitFilter.values)

        const treatmentsWithConfig =
          client?.getTreatmentsWithConfigByFlagSets(flagSets)
        updateTreatmentsFromSplit(treatmentsWithConfig || {})
      } catch (error) {
        const logger = await getLogger()
        logger.error(
          `${LOGGER_NAME}: Error initializing Split client - ${error.message}`,
        )
        updateTreatmentsFromSplit({})
      }
    } else {
      const logger = await getLogger()
      logger.warn(
        `${LOGGER_NAME}: The 'split' mode does not currently work without specified flag sets.`,
      )
      updateTreatmentsFromSplit({})
    }
  }
}
